/* eslint-disable no-use-before-define */
import React, {useEffect, useState} from 'react';
import {TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ComboBox = ({setSelected}) => {

    const [data, setData] = useState(null);


    useEffect(() => {
        fetch("/search/regions-with-guides")
            .then(response => response.json())
            .then(data => setData(data))
    }, []);


    const getOptions = () => {
        return Object.values(data);
    };

    return data ? <Autocomplete
        className={"top-search"}
        onChange={(event, value) => setSelected(value.id)}
        options={getOptions()}
        getOptionLabel={(option) => `${option.name}, ${option.countryName}`}
        style={{width: "100%", padding: 0}}
        renderInput={(params) =>
            <TextField
                {...params}
                label="¿Dónde buscas a un guía oficial?"
                variant={"outlined"}
            />
        }
    /> : null;
};

export const TourSearch = (props) => {

    const [selected, setSelected] = useState(null);

    const redirect = () => {
        if (selected) {
            window.location = `/tours/regiones/${selected}`
        }
    };


    return <div className="row" style={{"width": "400px"}}>
        <div className="col col-11">
            <ComboBox setSelected={(value) => setSelected(value)}/>
        </div>
        <div className="col col-1">
            <button
                onClick={redirect}
                type="button"
                className="btn btn-info"
               >
                ¡Vamos!
            </button>
        </div>
    </div>
};
