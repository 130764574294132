import React, { useState } from 'react';
import {useFormik} from 'formik';
import { Button } from '@material-ui/core';

export const PostSelector = ({page, defaultSort, defaultSearch}) => {


    const [changed, setChanged] = useState(false);
    const [sort, setSort] = useState(defaultSort ?? '');
    const [search, setSearch] = useState(defaultSearch ?? '');

    const url = `/blog?sortBy=${sort}&search=${search}`;

    return <div className="row">
        <div className="col-4">
            <select
                value={sort}
                className={"form-control"}
                onChange={(e) => {setSort(e.target.value); setChanged(true)}}>
                <option value="createdAt&orderBy=DESC">Más recientes primero</option>
                <option value="createdAt&orderBy=ASC">Más antiguos primero</option>
                <option value="views&orderBy=DESC">Más populares primero</option>
                <option value="countryCode&orderBy=ASC">País - alfabéticamente</option>
            </select>
        </div>
        <div className="col-4">
            <input
                defaultValue={search}
                type="text"
                placeholder={"Buscar por palabra o guía"}
                className={"form-control"}
                onInput={e => {setSearch(e.target.value); setChanged(true)}}
            />
        </div>

        <div className="col-4">
            {changed && <Button variant="outlined" onClick={() => { window.location = url }}>
                Ir
            </Button>}
        </div>
    </div>


};
