import React from 'react';

import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";

export const SocialShare = ({url}) => {
    return <div className={"social-share"}>
        <FacebookShareButton url={url}>
            <FacebookIcon size={32} round={false} />
        </FacebookShareButton>
        <TwitterShareButton url={url}>
            <TwitterIcon size={32} round={false} />
        </TwitterShareButton>
        <PinterestShareButton url={url}>
            <PinterestIcon size={32} round={false} />
        </PinterestShareButton>
        <WhatsappShareButton url={url}>
            <WhatsappIcon size={32} round={false} />
        </WhatsappShareButton>
    </div>
};
