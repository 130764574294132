/* eslint-disable no-use-before-define */
import React, {useEffect, useState} from 'react';
import {TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ComboBox = ({setSelected}) => {

    const [data, setData] = useState(null);


    useEffect(() => {
        fetch("/search/regions-with-guides")
            .then(response => response.json())
            .then(data => setData(data))
    }, []);


    const getOptions = () => {
        return Object.values(data);
    };

    return data ? <Autocomplete
        className={"top-search"}
        onChange={(event, value) => setSelected(value.id)}
        options={getOptions()}
        getOptionLabel={(option) => `${option.name}, ${option.countryName}`}
        style={{width: "100%", padding: 0}}
        renderInput={(params) =>
            <TextField
                {...params}
                label="¿Dónde buscas a un guía oficial?"
                variant={"outlined"}
            />
        }
    /> : null;
};

export const TourSearchMini = (props) => {

    const [selected, setSelected] = useState(null);

    const redirect = (value) => {
        setSelected(value);

        if (value) {
            window.location = `/tours/regiones/${value}`
        }
    };


    return <div className="top-search-mini">
            <ComboBox setSelected={(value) => redirect(value)}/>
        </div>

};
