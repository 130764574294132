export const PROVINCES = [
    { 'id': 28,  'name':  "Madrid"},
    { 'id': 2,  'name':  "Albacete"},
    { 'id': 3,  'name':  "Alicante/Alacant"},
    { 'id': 4,  'name':  "Almería"},
    { 'id': 1,  'name':  "Araba/Álava"},
    { 'id': 33,  'name':  "Asturias"},
    { 'id': 5,  'name':  "Ávila"},
    { 'id': 6,  'name':  "Badajoz"},
    { 'id': 7,  'name':  "Balears, Illes"},
    { 'id': 8,  'name':  "Barcelona"},
    { 'id': 48,  'name':  "Bizkaia"},
    { 'id': 9,  'name':  "Burgos"},
    { 'id': 10,  'name':  "Cáceres"},
    { 'id': 11,  'name':  "Cádiz"},
    { 'id': 39,  'name':  "Cantabria"},
    { 'id': 12,  'name':  "Castellón/Castelló"},
    { 'id': 13,  'name':  "Ciudad Real"},
    { 'id': 14,  'name':  "Córdoba"},
    { 'id': 15,  'name':  "Coruña, A"},
    { 'id': 16,  'name':  "Cuenca"},
    { 'id': 20,  'name':  "Gipuzkoa"},
    { 'id': 17,  'name':  "Girona"},
    { 'id': 18,  'name':  "Granada"},
    { 'id': 19,  'name':  "Guadalajara"},
    { 'id': 21,  'name':  "Huelva"},
    { 'id': 22,  'name':  "Huesca"},
    { 'id': 23,  'name':  "Jaén"},
    { 'id': 24,  'name':  "León"},
    { 'id': 25,  'name':  "Lleida"},
    { 'id': 27,  'name':  "Lugo"},
    { 'id': 29,  'name':  "Málaga"},
    { 'id': 30,  'name':  "Murcia"},
    { 'id': 31,  'name':  "Navarra"},
    { 'id': 32,  'name':  "Ourense"},
    { 'id': 34,  'name':  "Palencia"},
    { 'id': 35,  'name':  "Palmas, Las"},
    { 'id': 36,  'name':  "Pontevedra"},
    { 'id': 26,  'name':  "Rioja, La"},
    { 'id': 37,  'name':  "Salamanca"},
    { 'id': 40,  'name':  "Segovia"},
    { 'id': 41,  'name':  "Sevilla"},
    { 'id': 42,  'name':  "Soria"},
    { 'id': 38,  'name':  "Santa Cruz de Tenerife"},
    { 'id': 43,  'name':  "Tarragona"},
    { 'id': 44,  'name':  "Teruel"},
    { 'id': 45,  'name':  "Toledo"},
    { 'id': 46,  'name':  "Valencia/València"},
    { 'id': 47,  'name':  "Valladolid"},
    { 'id': 49,  'name':  "Zamora"},
    { 'id': 50,  'name':  "Zaragoza"},
    { 'id': 51,  'name':  "Ceuta"},
    { 'id': 52,  'name':  "Melilla" }
];
export const TOUR_TYPES = [
    {'id' : "WALKING_TOUR" , 'name' : __t.t('tour.types.WALKING_TOUR')},
    {'id' : "ON_VEHICLE" , 'name' : __t.t('tour.types.ON_VEHICLE')},
    {'id' : "MANY_DAYS" , 'name' : __t.t('tour.types.MANY_DAYS')},
    {'id' : "PANORAMIC" , 'name' : __t.t('tour.types.PANORAMIC')},
    {'id' : "TOURIST_ATTRACTION" , 'name' : __t.t('tour.types.TOURIST_ATTRACTION')},
    {'id' : "HIKING" , 'name' : __t.t('tour.types.HIKING')},
];

export const CUSTOMER_STATUSES = [
    {'id' : 0 , 'name' : __t.t('Activo')},
    {'id' : 1 , 'name' : __t.t('Inactivo')},
    {'id' : 1 , 'name' : __t.t('En proceso')}
];

export const RESERVATION_STATUS_OPTIONS = [
    {'id' : 0 , 'name' : __t.t('Activo')},
    {'id' : 1 , 'name' : __t.t('Realizado')},
    {'id' : 2 , 'name' : __t.t('Cancelado por guía')},
    {'id' : 3 , 'name' : __t.t('Cancelado por cliente')},
];

export const RESERVATION_STATUSES = [
    "Activo",
    "Realizado",
    "Cancelado por guía",
    "Cancelado por cliente"
];

export const ROLE_INDEXES = [
    {'id' : 'ROLE_USER' , 'name' : __t.t('ROLE_CUSTOMER_SUPER_ADMIN')},
    {'id' : 'ROLE_LIMITED_ADMIN' , 'name' : __t.t('ROLE_CUSTOMER_ADMIN')}
];
export const CHILDREN_POLICIES = [
    {'id' : "PLUS_6" , 'name' : __t.t('tour.children_policy_options.PLUS_6')},
    {'id' : "PLUS_3" , 'name' : __t.t('tour.children_policy_options.PLUS_3')},
    {'id' : "PLUS_12" , 'name' : __t.t('tour.children_policy_options.PLUS_12')},
    {'id' : "PLUS_18" , 'name' : __t.t('tour.children_policy_options.PLUS_18')},
    {'id' : "ANY" , 'name' : __t.t('tour.children_policy_options.ANY')}
];
export const HOST_INCLUDED = [
    {'id' : false , 'name' : __t.t('tour.host_included.no')},
    {'id' : true , 'name' : __t.t('tour.host_included.yes')},
];
export const PHYSICAL_EFFORTS = [
    {'id' : "VERY_LOW" , 'name' : __t.t('tour.physical_effort_options.VERY_LOW')},
    {'id' : "LOW" , 'name' : __t.t('tour.physical_effort_options.LOW')},
    {'id' : "MODERATE" , 'name' : __t.t('tour.physical_effort_options.MODERATE')},
    {'id' : "HIGH" , 'name' : __t.t('tour.physical_effort_options.HIGH')},
];

export const CREDIT_SCOPES = [
    { id: 0, name: 'credit_scopes.local' },
    { id: 1, name: 'credit_scopes.regional' },
    { id: 2, name: 'credit_scopes.national' },
];

export const ASOCIATION_LEVELS_TRUE = [
    { id: 0, name: 'Sí, es obligatorio' },
    { id: 1, name: 'Sí, aunque la membresía es opcional' },
];

export const ASOCIATION_LEVELS_FALSE = [
    { id: 2, name: 'No, porque no es obligatorio y he decidido no ser miembro de la asociación'},
    { id: 3, name: 'No, porque no hay ninguna asociación oficial en mi ciudad, región o país' },
];

export const BOOLEAN_CHOICES = [
    {'id' : false, 'name' : 'No'},
    {'id' : true, 'name' : 'Sí'},
];

export const TEACHER_TYPE_CHOICES = [
    {'id' : false, 'name' : 'Docentes'},
    {'id' : true, 'name' : 'Guías docentes'},
];

export const INITIAL_POINT_CHOICES = [
    {'id' : false, 'name' : 'A acordar con el cliente por mensaje a través de la plataforma (solo disponible para tours privados'},
    {'id' : true, 'name' : 'Punto de partida fijo'},
];

export const CURRENCIES = [
    {'id' : 0, name: '€'},
    {'id' : 1, name: '£'},
    {'id' : 2, name: '$'}
];

export const TOUR_STATUS_CHOICES = [
    {"id": 0, 'name' : "Nuevo"},
    {"id": 1, 'name' : "En proceso"},
    {"id": 2, 'name' : "Publicado"},
    {"id": 3, 'name' : "Cambios pendientes"},
    {"id": 4, 'name' : "Pendientes"}
];

export const CALENDAR_MESSAGES = {
    next: "Siguiente",
    previous: "Atrás",
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: 'Día',
    work_week: "Semana laboral",
    date: "Fecha",
    agenda: "Agenda",
    time: "Horario",
    event: "Tareas",
    more: "Más",
    noEventsInRange: 'No hay tareas en este rango',
    showMore: (total) => `+ ${total}`
};

export const EVALUATION_TIME_OPTIONS   = [
    { id: 0, name : __t.t('course.completion_time.two_hours_or_less')},
    { id: 1, name : __t.t('course.completion_time.between_2and10_hours')},
    { id: 2, name : __t.t('course.completion_time.twenty_hours_or_more')},
    { id: 3, name : __t.t('course.completion_time.fourty_hours_or_more')}
];
export const EVALUATION_DIFFICULTY_OPTIONS = [
    { id: 0, name : __t.t('course.difficulty.easy')},
    { id: 1, name : __t.t('course.difficulty.normal')},
    { id: 2, name : __t.t('course.difficulty.hard')}
];


export const EVALUATION_METHOD_OPTIONS = [
    { id: 0, name : __t.t('course.evaluation_method.test_single_answer')},
    { id: 1, name : __t.t('course.evaluation_method.test_multiple_answer')},
    { id: 2, name : __t.t('course.evaluation_method.academic_essay')},
    { id: 3, name : __t.t('course.evaluation_method.practical_case')},
    { id: 4, name : __t.t('course.evaluation_method.video')}
];


export const EVALUATION_METHODS = [
    __t.t('course.evaluation_method.test_single_answer'),
    __t.t('course.evaluation_method.test_multiple_answer'),
    __t.t('course.evaluation_method.academic_essay'),
    __t.t('course.evaluation_method.practical_case'),
    __t.t('course.evaluation_method.video')
];

export const EVALUATION_TIME = [
    __t.t('course.completion_time.two_hours_or_less'),
    __t.t('course.completion_time.between_2and10_hours'),
    __t.t('course.completion_time.twenty_hours_or_more'),
    __t.t('course.completion_time.fourty_hours_or_more')
];

export const EVALUATION_DIFFICULTY = [
    __t.t('course.difficulty.easy'),
    __t.t('course.difficulty.normal'),
    __t.t('course.difficulty.hard')
];
export const EVALUATION_METHOD= [
    __t.t('course.evaluation_method.test_single_answer'),
    __t.t('course.evaluation_method.test_multiple_answer'),
    __t.t('course.evaluation_method.academic_essay'),
    __t.t('course.evaluation_method.practical_case'),
    __t.t('course.evaluation_method.video')
];
export const EDITOR_CONFIG = {
    language : 'es',
    config: {
        width: 600,
        height: 600
    },
    fontColor: {
        colors: [
            {
                color: 'hsl(0, 0%, 0%)',
                label: 'Negro'
            },
            {
                color: 'hsl(351, 80%, 56%)',
                label: 'Rojo Guías Oficiales'
            },
            {
                color: '#74329E',
                label: 'Violeta Guías Oficiales'
            },
            {
                color: 'hsl(0, 0%, 90%)',
                label: 'Gris claro'
            },
            {
                color: 'hsl(0, 0%, 100%)',
                label: 'Blanco',
            },
            '#FF0000', '#00FF00', '#0000FF'
        ]
    },
    font: {
        defaultLabel : 'Verdana'
    },
    fontFamily : {
        options :  [
            'Verdana',
            'Montserrat',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
        ]
    }

};
export const COURSE_TYPES = [
    { "id" : 0, "name" : "Historia"},
    { "id" : 1, "name" : "Arte"},
    { "id" : 2, "name" : "Sostenibilidad"},
    { "id" : 3, "name" : "Turismo inclusivo"},
    { "id" : 4, "name" : "Técnicas de guiado"},
    { "id" : 5, "name" : "Marketing"},
    { "id" : 6, "name" : "Redes Sociales"},
    { "id" : 7, "name" : "Web & SEO"},
    { "id" : 8, "name" : "Otros"}
];
export const PAGE_TEMPLATES = [
    { "id" : 0, "name" : "Full"},
    { "id" : 1, "name" : "Aside"},
];


export const CURRENCY_CHOICES = [
    {
        "name": "Euro",
        "id": "EUR"
    },
    {
        "name": "British pounds sterling",
        "id": "GBP"
    },
    {
        "name": "US dollars",
        "id": "USD"
    },
    {
        "name": "Canadian dollars",
        "id": "CAD"
    },
    {
        "name": "UAE dirhams",
        "id": "AED"
    },
    {
        "name": "Afghan Afghanis",
        "id": "AFN"
    },
    {
        "name": "Albanian lekë",
        "id": "ALL"
    },
    {
        "name": "Armenian drams",
        "id": "AMD"
    },
    {
        "name": "Argentine pesos",
        "id": "ARS"
    },
    {
        "name": "Australian dollars",
        "id": "AUD"
    },
    {
        "name": "Azerbaijani manats",
        "id": "AZN"
    },
    {
        "name": "Bosnia-Herzegovina convertible marks",
        "id": "BAM"
    },
    {
        "name": "Bangladeshi takas",
        "id": "BDT"
    },
    {
        "name": "Bulgarian leva",
        "id": "BGN"
    },
    {
        "name": "Bahraini dinars",
        "id": "BHD"
    },
    {
        "name": "Burundian francs",
        "id": "BIF"
    },
    {
        "name": "Brunei dollars",
        "id": "BND"
    },
    {
        "name": "Bolivian bolivianos",
        "id": "BOB"
    },
    {
        "name": "Brazilian reals",
        "id": "BRL"
    },
    {
        "name": "Botswanan pulas",
        "id": "BWP"
    },
    {
        "name": "Belarusian rubles",
        "id": "BYN"
    },
    {
        "name": "Belize dollars",
        "id": "BZD"
    },
    {
        "name": "Congolese francs",
        "id": "CDF"
    },
    {
        "name": "Swiss francs",
        "id": "CHF"
    },
    {
        "name": "Chilean pesos",
        "id": "CLP"
    },
    {
        "name": "Chinese yuan",
        "id": "CNY"
    },
    {
        "name": "Colombian pesos",
        "id": "COP"
    },
    {
        "name": "Costa Rican colóns",
        "id": "CRC"
    },
    {
        "name": "Cape Verdean escudos",
        "id": "CVE"
    },
    {
        "name": "Czech Republic korunas",
        "id": "CZK"
    },
    {
        "name": "Djiboutian francs",
        "id": "DJF"
    },
    {
        "name": "Danish kroner",
        "id": "DKK"
    },
    {
        "name": "Dominican pesos",
        "id": "DOP"
    },
    {
        "name": "Algerian dinars",
        "id": "DZD"
    },
    {
        "name": "Estonian kroons",
        "id": "EEK"
    },
    {
        "name": "Egyptian pounds",
        "id": "EGP"
    },
    {
        "name": "Eritrean nakfas",
        "id": "ERN"
    },
    {
        "name": "Ethiopian birrs",
        "id": "ETB"
    },
    {
        "name": "Georgian laris",
        "id": "GEL"
    },
    {
        "name": "Ghanaian cedis",
        "id": "GHS"
    },
    {
        "name": "Guinean francs",
        "id": "GNF"
    },
    {
        "name": "Guatemalan quetzals",
        "id": "GTQ"
    },
    {
        "name": "Hong Kong dollars",
        "id": "HKD"
    },
    {
        "name": "Honduran lempiras",
        "id": "HNL"
    },
    {
        "name": "Croatian kunas",
        "id": "HRK"
    },
    {
        "name": "Hungarian forints",
        "id": "HUF"
    },
    {
        "name": "Indonesian rupiahs",
        "id": "IDR"
    },
    {
        "name": "Israeli new sheqels",
        "id": "ILS"
    },
    {
        "name": "Indian rupees",
        "id": "INR"
    },
    {
        "name": "Iraqi dinars",
        "id": "IQD"
    },
    {
        "name": "Iranian rials",
        "id": "IRR"
    },
    {
        "name": "Icelandic krónur",
        "id": "ISK"
    },
    {
        "name": "Jamaican dollars",
        "id": "JMD"
    },
    {
        "name": "Jordanian dinars",
        "id": "JOD"
    },
    {
        "name": "Japanese yen",
        "id": "JPY"
    },
    {
        "name": "Kenyan shillings",
        "id": "KES"
    },
    {
        "name": "Cambodian riels",
        "id": "KHR"
    },
    {
        "name": "Comorian francs",
        "id": "KMF"
    },
    {
        "name": "South Korean won",
        "id": "KRW"
    },
    {
        "name": "Kuwaiti dinars",
        "id": "KWD"
    },
    {
        "name": "Kazakhstani tenges",
        "id": "KZT"
    },
    {
        "name": "Lebanese pounds",
        "id": "LBP"
    },
    {
        "name": "Sri Lankan rupees",
        "id": "LKR"
    },
    {
        "name": "Lithuanian litai",
        "id": "LTL"
    },
    {
        "name": "Latvian lati",
        "id": "LVL"
    },
    {
        "name": "Libyan dinars",
        "id": "LYD"
    },
    {
        "name": "Moroccan dirhams",
        "id": "MAD"
    },
    {
        "name": "Moldovan lei",
        "id": "MDL"
    },
    {
        "name": "Malagasy Ariaries",
        "id": "MGA"
    },
    {
        "name": "Macedonian denari",
        "id": "MKD"
    },
    {
        "name": "Myanma kyats",
        "id": "MMK"
    },
    {
        "name": "Macanese patacas",
        "id": "MOP"
    },
    {
        "name": "Mauritian rupees",
        "id": "MUR"
    },
    {
        "name": "Mexican pesos",
        "id": "MXN"
    },
    {
        "name": "Malaysian ringgits",
        "id": "MYR"
    },
    {
        "name": "Mozambican meticals",
        "id": "MZN"
    },
    {
        "name": "Namibian dollars",
        "id": "NAD"
    },
    {
        "name": "Nigerian nairas",
        "id": "NGN"
    },
    {
        "name": "Nicaraguan córdobas",
        "id": "NIO"
    },
    {
        "name": "Norwegian kroner",
        "id": "NOK"
    },
    {
        "name": "Nepalese rupees",
        "id": "NPR"
    },
    {
        "name": "New Zealand dollars",
        "id": "NZD"
    },
    {
        "name": "Omani rials",
        "id": "OMR"
    },
    {
        "name": "Panamanian balboas",
        "id": "PAB"
    },
    {
        "name": "Peruvian nuevos soles",
        "id": "PEN"
    },
    {
        "name": "Philippine pesos",
        "id": "PHP"
    },
    {
        "name": "Pakistani rupees",
        "id": "PKR"
    },
    {
        "name": "Polish zlotys",
        "id": "PLN"
    },
    {
        "name": "Paraguayan guaranis",
        "id": "PYG"
    },
    {
        "name": "Qatari rials",
        "id": "QAR"
    },
    {
        "name": "Romanian lei",
        "id": "RON"
    },
    {
        "name": "Serbian dinars",
        "id": "RSD"
    },
    {
        "name": "Russian rubles",
        "id": "RUB"
    },
    {
        "name": "Rwandan francs",
        "id": "RWF"
    },
    {
        "name": "Saudi riyals",
        "id": "SAR"
    },
    {
        "name": "Sudanese pounds",
        "id": "SDG"
    },
    {
        "name": "Swedish kronor",
        "id": "SEK"
    },
    {
        "name": "Singapore dollars",
        "id": "SGD"
    },
    {
        "name": "Somali shillings",
        "id": "SOS"
    },
    {
        "name": "Syrian pounds",
        "id": "SYP"
    },
    {
        "name": "Thai baht",
        "id": "THB"
    },
    {
        "name": "Tunisian dinars",
        "id": "TND"
    },
    {
        "name": "Tongan paʻanga",
        "id": "TOP"
    },
    {
        "name": "Turkish Lira",
        "id": "TRY"
    },
    {
        "name": "Trinidad and Tobago dollars",
        "id": "TTD"
    },
    {
        "name": "New Taiwan dollars",
        "id": "TWD"
    },
    {
        "name": "Tanzanian shillings",
        "id": "TZS"
    },
    {
        "name": "Ukrainian hryvnias",
        "id": "UAH"
    },
    {
        "name": "Ugandan shillings",
        "id": "UGX"
    },
    {
        "name": "Uruguayan pesos",
        "id": "UYU"
    },
    {
        "name": "Uzbekistan som",
        "id": "UZS"
    },
    {
        "name": "Venezuelan bolívars",
        "id": "VEF"
    },
    {
        "name": "Vietnamese dong",
        "id": "VND"
    },
    {
        "name": "CFA francs BEAC",
        "id": "XAF"
    },
    {
        "name": "CFA francs BCEAO",
        "id": "XOF"
    },
    {
        "name": "Yemeni rials",
        "id": "YER"
    },
    {
        "name": "South African rand",
        "id": "ZAR"
    },
    {
        "name": "Zambian kwachas",
        "id": "ZMK"
    },
    {
        "name": "Zimbabwean Dollar",
        "id": "ZWL"
    }
];

