import React, {useDebugValue, useState} from 'react';
import {Field, FormikProvider, useFormik} from 'formik';
import range from 'lodash/range';

import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {zhCN} from '@material-ui/core/locale';
import Moment from 'moment';
import esLocale from "date-fns/locale/es";

import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';

import {CalendarToday} from '@material-ui/icons';
import DateFnsUtils from "@date-io/date-fns";
import {TextField} from "ra-ui-materialui";

const theme = createTheme(
    {
        palette: {
            primary: {main: '#E83450'},
        },
    },
    zhCN,
);

const TOUR_TYPE_SINGLE = 0;
const TOUR_TYPE_GROUPAL = 1;


const getIsAvailable = (d, avalaibility) => {

    const date = new Date(d);

    date.setHours(6);
    date.setMinutes(0);

    if (!avalaibility || !avalaibility.length) return false;

    let notAvailable = true;

    avalaibility.forEach(a => {

        const dateStart = Moment(String(a?.dateStart?.date).replace(/[0-9]{2}:00:00/, "00:00:00"));
        const dateEnd = Moment(String(a?.dateEnd?.date).replace(/[0-9]{2}:00:00/, "23:59:59"));

        if (dateStart.isBefore(date) && dateEnd.isAfter(date)) {
            notAvailable = false;
        }


    });



    return notAvailable;


};


const DatePickerField = ({field, form, avalaibility, ...other}) => {


    console.info("avalaibility", avalaibility);

    const currentError = form.errors[field.name];

    const setDate = (date) => {
        // if (getIsAvailable(date, avalaibility)) {
            form.setFieldValue(field.name, date, false);
        // } else {
        //     form.setFieldValue(field.name, null, false)
        // }
    };

    return (
        <div className="form-control reservation-date-picker">
            <DatePicker
                components={{OpenPickerIcon: CalendarToday}}
                clearable
                required
                fullWidth
                disablePast
                name={field.name}
                okLabel="Aceptar"
                clearLabel="Borrar"
                cancelLabel="Cancelar"
                value={field.value}
                format="dd/MM/yyyy"
                helperText={currentError}
                shouldDisableDate={(date) => getIsAvailable(date, avalaibility)}
                onChange={date => setDate(date)}
            />
            <div className="calendar-icon">
                <CalendarToday />
            </div>
        </div>
    );
};


const Form = ({
                  tourId,
                  onClose,
                  privatePrice,
                  groupalPrice,
                  groupalPriceChildren,
                  avalaibility,
                  groupalAvalaibility,
                  groupalMaxPeople,
                  isPrivate,
                  isGroupal
              }
) => {

    const [submitted, setSubmitted] = useState(0);

    useDebugValue(submitted ? 'Online' : 'Offline');

    const defaultType = !isPrivate ? 1 : 0;

    const formik = useFormik({
        initialValues: {
            email: '',
            tour: `/api/tours/${tourId}`,
            type: defaultType,
            people: 0,
            children: 0,
            contactPhone: '',
            date: null
        },
        onSubmit: values => {

            if (
                values.email !== ''
            ) {

                fetch('/api/reservations', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                })
                    .then(res => res.json())

                    .then(res => {
                        setSubmitted(true);
                    });
            }
        },
    });

    const {values} = formik;

    const groupRange = parseInt(groupalMaxPeople) + 1;

    const getPrice = () => {

        if (!values.people) return null;

        let text = `Precio total: `;

        if (values.type === TOUR_TYPE_SINGLE) {
            text += privatePrice
        }

        if (values.type === TOUR_TYPE_GROUPAL) {

            let price = 0;

            if (values.people && groupalPrice) {
                price += values.people * groupalPrice;
            }

            if (values.children && groupalPriceChildren) {
                price += values.people * groupalPriceChildren;
            }

            text += price;
        }

        return text + '€'

    };

    const setPeople = (e) => {
        formik.setFieldValue("people", parseInt(e.target.value));
    };

    const setChildren = (e) => {
        formik.setFieldValue("children", parseInt(e.target.value));
    };

    const adultText = values.type === TOUR_TYPE_SINGLE ? "Selecciona el nº de personas" : "Número de adultos";

    const avalaibilities = values.type === TOUR_TYPE_SINGLE ? avalaibility : groupalAvalaibility;

    return (
        !submitted ? <form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col col-12 mb-2">
                    <input
                        id="name"
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Tu nombre"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.name}
                    />
                </div>
                <div className="col col-12 mb-2">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="Tu correo electrónico"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                </div>
                <div className="col col-12 mb-2">
                    <input
                        id="contactPhone"
                        name="contactPhone"
                        type="contactPhone"
                        className="form-control"
                        required
                        placeholder="Teléfono de contacto"
                        onChange={formik.handleChange}
                        value={formik.values.contactPhone}
                    />
                </div>
                {isPrivate && <div className="col col-5 mb-2">
                    <input
                        name="type"
                        type="radio"
                        onChange={() => formik.setFieldValue("type", 0)}
                        checked={values.type === 0}
                        value={0}
                    /> En privado
                </div>
                }
                {isGroupal &&
                <div className="col col-7 mb-2">
                    <input
                        name="type"
                        type="radio"
                        onChange={() => formik.setFieldValue("type", 1)}
                        checked={values.type === 1}
                        value={1}
                    />Unirme a un grupo
                </div>
                }
                <div className="col col-12 mb-2">
                    <select
                        required
                        name="people"
                        className={"form-control"}
                        onChange={(e) => setPeople(e)}
                        value={formik.values.people}
                    >
                        <option value="">{adultText}</option>
                        {range(1, groupRange).map(k => <option value={k}>{k}</option>)}
                    </select>
                </div>
                {
                    (values.type === TOUR_TYPE_GROUPAL && formik.values.people) ? <div className="col col-12 mb-2">
                        <select
                            required
                            name="people"
                            className={"form-control"}
                            onChange={(e) => setChildren(e)}
                            value={formik.values.children}
                        >
                            <option value="">Número de niños</option>
                            {range(1, groupRange).map(k => <option value={k}>{k}</option>)}
                        </select>
                    </div> : null
                }
                <div className="col col-12 mb-2">
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <FormikProvider value={formik}>
                            <Field
                                name="date"
                                component={DatePickerField}
                                avalaibility={avalaibilities}
                            />
                        </FormikProvider>
                    </MuiPickersUtilsProvider>
                </div>
                <div className="col col-12 mt-2 text-center">
                    <b>{getPrice()}</b>
                </div>
                <div>
                    <input type="hidden"
                           onChange={formik.handleChange}
                           value={formik.values.toUser}
                    />
                </div>
                <div className="col col-12 mt-4">
                    <button type="submit" className="btn branding-background-2nd color-white tcenter">RESERVAR</button>
                </div>
            </div>


        </form> : <div className={"text-center"}>
            <input type="checkbox" value={submitted} onChange={() => setSubmitted(!submitted)}/>
            <p className="alert alert-info branding-background color-white">
                Reserva completa, el guía se pondrá en contacto contigo a la mayor brevedad posible
            </p>
        </div>
    );
};

export const ReservationForm =
    ({
         guideId,
         privatePrice,
         groupalPrice,
         groupalPriceChildren,
         avalaibility,
         groupalAvalaibility,
         groupalMaxPeople,
         isPrivate,
         isGroupal,
         tourId
     }) => {

        const [show, setShow] = useState(false);

        return <ThemeProvider theme={theme}>
            <Form
                guideId={guideId}
                tourId={tourId}
                privatePrice={privatePrice}
                groupalPrice={groupalPrice}
                groupalPriceChildren={groupalPriceChildren}
                avalaibility={avalaibility}
                groupalMaxPeople={groupalMaxPeople}
                groupalAvalaibility={groupalAvalaibility}
                isPrivate={isPrivate}
                isGroupal={isGroupal}
            />
        </ThemeProvider>

    };
