import 'core-js';
import ReactDOM from 'react-dom';
import React from 'react';
import {NewsletterForm} from "./components/NewsletterForm";
import {TourSearch} from "./components/TourSearch";
import {CountryMap} from "./components/CountryMap";
import {PostSelector} from "./components/PostSelector";
import {SocialShare} from "./components/SocialShare";
import {TourSelector} from "./components/TourSelector";
import {ContactGuideForm} from "./components/ContactGuideForm";
import {ReservationForm} from "./components/ReservationForm";
import {TourSearchMini} from "./components/TourSearchMini";

var Sticky = require('sticky-js');

const sliders = () => {
    $('.bxslider').bxSlider({
        mode: 'fade'
    });


    $('body').on('click', '.bxslider .link', function(e)  {
        e.stopPropagation();
        e.preventDefault();

        const url = $(this).attr('href');

        window.location = url;

    });

};

const fonts = () => {
    $(document).ready(function () {
        $(window).delay(100).queue(
            function(next) {
                fitty('.fitty');
                next();
            }
        );
    });
};

const newsletters = () => {

    var node = $('#data-newsletters-root');
    if (!node.length) {
        return;
    }

    ReactDOM.render(
        <NewsletterForm/>,
        document.getElementById('data-newsletters-root')
    );
};

const tourSearch = () => {

    var node = $('#data-tour-search');
    if (!node.length) {
        return;
    }

    ReactDOM.render(
        <TourSearch/>,
        document.getElementById('data-tour-search')
    );
};

const tourSearchTop = () => {

    var node = $('#data-tour-search-mini');
    if (!node.length) {
        return;
    }

    ReactDOM.render(
        <TourSearchMini/>,
        document.getElementById('data-tour-search-mini')
    );
};

const postSelector = () => {

    var node = $('#data-post-selector');

    var page =   node.attr('page');
    var sort =   node.attr('sort');
    var search = node.attr('search');

    if (!node.length) {
        return;
    }

    ReactDOM.render(
        <PostSelector page={page} defaultSort={sort} defaultSearch={search}/>,
        document.getElementById('data-post-selector')
    );
};

const countryMap = () => {

    const node = $('#data-country-map');

    if (!node.length) {
        return;
    }

    const width = node.width() + 'px';

    ReactDOM.render(
        <CountryMap width={width}/>,
        document.getElementById('data-country-map')
    );
};

const socialShare = () => {
    const node = $('#data-social-share');

    if (!node.length) {
        return false;
    }

    const url = node.attr("url");

    ReactDOM.render(
        <SocialShare url={url}/>,
        document.getElementById('data-social-share')
    );

};

const tourSelector = () => {
    const node = $('#data-tour-selector');

    if (!node.length) {
        return false;
    }

    const id = node.attr("data-guide-id");
    const guideSlug   = node.attr("data-guide-slug");
    const countrySlug = node.attr("data-country-slug");
    const width = node.width();


    ReactDOM.render(
        <TourSelector guideId={id} guideSlug={guideSlug} countrySlug={countrySlug}/>,
        document.getElementById('data-tour-selector')
    );

};

const tourReservation = () => {

    const node = $('#data-tour-reservation');

    if (!node.length) {
        return false;
    }

    const guideId = node.attr("data-guide-id");
    const tourId   = node.attr("data-tour-id");
    const privatePrice   = node.attr("data-tour-private-price");
    const groupalPrice   = node.attr("data-tour-groupal-price");
    const groupalPriceChildren   = node.attr("data-tour-groupal-price-children");
    const groupalMaxPeople   = node.attr("data-tour-max-group");

    const isPrivate   = node.attr("data-tour-isprivate");
    const isGroupal   = node.attr("data-tour-isgroupal");

    const avalaibility = JSON.parse(node.attr('data-tour-avalaibilities'));
    const groupalAvalaibility = JSON.parse(node.attr('data-tour-groupal-avalaibilities'));



    ReactDOM.render(
        <ReservationForm
            isGroupal={isGroupal}
            isPrivate={isPrivate}
            tourId={tourId}
            guideId={guideId}
            privatePrice={privatePrice}
            groupalPrice={groupalPrice}
            groupalPriceChildren={groupalPriceChildren}
            avalaibility={avalaibility}
            groupalMaxPeople={groupalMaxPeople}
            groupalAvalaibility={groupalAvalaibility}
        />,
        document.getElementById('data-tour-reservation')
    );

};

const contactGuide = () => {

    const nodes = $('div[data-contact-guide]');

    if (!nodes.length) {
        return false;
    }

    nodes.each(function () {

        const id = $(this).attr("data-guide-id");
        const text = $(this).attr("data-text");
        const title = $(this).attr("data-title");

        ReactDOM.render(
            <ContactGuideForm guideId={id} buttonText={text} title={title}/>,
            $(this)[0]
        );
    });


};

const topBarScroll = () => {


    if (!$('.topslider').length) {
        $('.topbar .navbar').addClass("scrolled");
        $('body').css({'paddingTop' : '3em'});
        return;
    }


    if ($('.topbar .navbar').hasClass("scrolled")) {
        return;
    }


    const checkHeight = () => {
        const scrollTop = $(window).scrollTop();
        const navHeight = $('.topbar .navbar').outerHeight();

        if (scrollTop >= navHeight) {
            $('.topbar .navbar').addClass("scrolled");
        } else {
            $('.topbar .navbar').removeClass("scrolled");
        }
    };

    $(document).ready(() => {
       checkHeight();
    });

    $(window).on('scroll', (e) => {
        checkHeight();
    });


};

const jsLinks = () => {
    $('body').on('click', '.link', function () {
       window.location = $(this).attr('href');
    });
};


$(document).ready(function () {

    sliders();
    fonts();
    newsletters();
    tourSearch();
    countryMap();
    postSelector();
    socialShare();
    tourSelector();
    contactGuide();
    tourReservation();

    tourSearchTop();
    topBarScroll();

    jsLinks();

});






