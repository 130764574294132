import React, {useState} from 'react';
import {useFormik} from 'formik';
import {Dialog, DialogActions, DialogContent, DialogTitle, Toolbar, ButtonGroup} from '@material-ui/core';


const Form = ({guideId, onClose}) => {

    const [submitted, setSubmitted] = useState(0);


    const formik = useFormik({
        initialValues: {
            email: '',
            toUser: `/api/users/${guideId}`
        },
        onSubmit: values => {

            if (
                values.email !== ''
            ) {

                fetch('/api/notifications', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                })
                    .then(res => res.json())

                    .then(res => {
                        console.info('submitted');
                        setSubmitted(true);
                    });
            }
        },
    });

    return (
        !submitted ? <form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col col-12 mb-4">
                    <input
                        id="name"
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Tu nombre"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.name}
                    />
                </div>
                <div className="col col-12 mb-4">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="Tu correo electrónico"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                </div>
                <div className="col col-12 mb-4">
                    <textarea
                        id="content"
                        name="content"
                        className="form-control"
                        placeholder="Tu mensaje"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.content}
                    />
                </div>
                <div>
                    <input type="hidden"
                           onChange={formik.handleChange}
                           value={formik.values.toUser}
                    />
                </div>
                <div className="col col-12 mt-4">
                    <button onClick={() => {onClose()}} type="button" className="m-auto btn btn-default">Cerrar</button>
                    <button type="submit" className="btn btn-info bg-green">Enviar</button>
                </div>
            </div>
        </form> : <div>
            <p className="alert alert-info">Mensaje enviado!</p>
            <div className="col col-12 mt-4">
                <button onClick={() => {onClose()}} type="button" className="m-auto btn btn-default">Cerrar</button>
            </div>
        </div>
    );
};

export const ContactGuideForm = ({guideId, buttonText, title}) => {

    const [show, setShow] = useState(false);


    return !show ?
        <button
            onClick={() => {setShow(!show)}}
            type={"button"}
            className={"btn btn-info tcenter bg-green px-4 py-2 m2"}>
            {buttonText}
        </button> : <Dialog open={true}>
            <DialogTitle>
                {title}
                <hr/>
            </DialogTitle>
            <DialogContent>
                <Form guideId={guideId} onClose={() => {setShow(false)}}/>
            </DialogContent>
        </Dialog>;

};


