import React, {useEffect, useState} from 'react';
import ReactMapboxGl, {Feature, Layer, Popup} from 'react-mapbox-gl';
import {Box, Button, CircularProgress, Grid} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {LinearProgress} from "ra-ui-materialui";

const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiZW1nb3lhIiwiYSI6ImNsMDJjdzV4eDA0dHgzY3AzM3ExbDVtYTkifQ.fAyzbI8ikgqg8VQIoWzKMQ'
});


const POSITION_CIRCLE_PAINT = {
    'circle-stroke-width': 4,
    'circle-radius': 12,
    'circle-blur': 0.15,
    'circle-color': '#74329E',
    'circle-stroke-color': 'white'
};

const getCoords = (item) => {
    return [item.longitude, item.latitude];
};


const Pop = ({item, onClose}) => {

    if (!item) return null;

    return <Popup coordinates={getCoords(item)}>
        <Grid container>
            <Grid item xs={9}>
                <h5><u>{item.name}</u></h5>
            </Grid>
            <Grid item xs={3}>
                <Button onClick={onClose} size={"small"} startIcon={<Close/>}>Cerrar</Button>
            </Grid>
            <Grid item xs={12}>
                <p>{item.acreditationMethod}</p>
            </Grid>
            <Grid item xs={12}>
                <a href={`/como-reconocer-a-un-guia-oficial/${item.code}`} alt="">
                    Más información
                </a>
            </Grid>
        </Grid>
    </Popup>
};

export const CountryMap = ({width = "100vw"}) => {

    const [data, setData] = useState(null);
    const [center, setCenter] = useState(null);
    const [marked, setMarked] = useState(null);


    const setDefaults = (data) => {
        setData(data);
        setCenter(getCoords(data[0]));
    };

    const closeTip = () => {
        setMarked(null);
    };

    useEffect(() => {
        fetch("/api/countries.json?latitude[gt]=-10000&longitude[gt]=-10000")
            .then(response => response.json())
            .then(data => setDefaults(data))
    }, []);


    if (!data || !center) return <LinearProgress/>;

    return <Box>

        <Grid container spacing={2} className={"country-selector"}>
            <Grid item xs={4}>
                <select onChange={(e) => {
                    const item = data[e.target.value];
                    setCenter([item.longitude, item.latitude]);
                    setMarked(e.target.value);
                }}>
                    <option value="">Selecciona el país</option>
                    {data.map((m, loop) => <option key={m.id} value={loop}>
                        {m.name}
                    </option>)}
                </select>
            </Grid>
            <Grid item xs={8}>
                <label>o busca el país que quieras en el mapa</label>
            </Grid>
        </Grid>

        <br/>

        <Map
            style="mapbox://styles/mapbox/streets-v11"
            center={center}
            zoom={[4]}
            containerStyle={{
                height: "60vh",
                width: width,
                marginBottom: "2em"
            }}
        >
            <Layer
                type="circle"
                id="marker"
                paint={POSITION_CIRCLE_PAINT}
            >
                {data.map((m, loop) =>
                    <Feature key={m.id}
                             coordinates={getCoords(m)}
                             onClick={() => {
                                 setMarked(loop);
                                 setCenter(getCoords(m))
                             }}
                    />)}
            </Layer>
            <Pop item={data[marked]} onClose={() => {
                closeTip()
            }}/>
        </Map>
    </Box>
};




