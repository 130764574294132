import React, {useEffect, useState} from 'react';
import {Card, CardContent, CircularProgress, Grid} from '@material-ui/core';
import {TOUR_TYPES} from "../../backoffice/components/AdminRT/__enums/Enums";

const timeUrl = "/static/img/time_red.png";
const peopleUrl = "/static/img/people_red.png";
const iconStyle = {'width': "1em", 'height': 'auto'};

const TourBlock = ({tour, guideSlug, countrySlug}) => {


    const [district, setDistrict] = useState(null);

    useEffect(() => {
        fetch(tour.district)
            .then(response => response.json())
            .then(data => setDistrict(data))
    }, []);


    const url = district ? `/tours/${countrySlug}/${district.slug}/${guideSlug}/${tour.slug}` : '';


    return district ? <Grid item xs={12} lg={3} onClick={() => {
        window.location = url
    }} className={"tour-grid"}>
        <Card style={{"position": 'relative'}}>
            <div className="branding-background color-white top-snippet p-2">
                {__t.t('tour.types.' + tour.tourType)}
            </div>
            <div className="tour-grid-image" style={{'background': `url('${tour.mediaUrl}')`}}>
            </div>
            <div className="p-2 branding-background-2nd text-center color-white tour-grid-name" title={tour.name}>
                <b>{tour.name}</b>
            </div>
            <CardContent className={"word-wrap tour-grid-description"}>
                {tour.shortDescription}
            </CardContent>
            <CardContent>
                <hr/>
                <Grid container>
                    <Grid item xs={6}>
                        <div className={"tour-grid-duration"}>
                            <img style={iconStyle} src={timeUrl}/>
                            &nbsp;{tour.tourDuration}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <img style={iconStyle} src={peopleUrl}/>
                        <small>&nbsp;{tour.isPrivate ? "En privado" : ''}</small>
                        <small>&nbsp;{tour.isGroupal ? "En grupo" : ''}</small>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Grid> : null;
}


const ToursBlock = ({data, guideSlug, countrySlug}) => {
    return <Grid container spacing={4}>
        {data.map(tour => {
            return <TourBlock tour={tour} guideSlug={guideSlug} countrySlug={countrySlug}/>
        })}
    </Grid>

};

export const TourSelector = ({guideId, guideSlug, countrySlug}) => {

    const baseRoute = `/api/tours.json?pagination=false&createdBy=${guideId}&approved=true&published=true`;
    const [data, setData] = useState(null);
    const [filterValue, setFilterValue] = useState(null);
    const [route, setRoute] = useState(baseRoute);


    const setFilter = (value) => {

        let newRoute = baseRoute;

        if (value) {
            newRoute = `${baseRoute}&tourType=${value}`;
        }

        fetch(newRoute)
            .then(response => response.json())
            .then(data => {
                setData(data)
            });

        setFilterValue(value);
        setRoute(newRoute);

    };

    useEffect(() => {
        fetch(route)
            .then(response => response.json())
            .then(data => setData(data))
    }, []);


    return <div className="row my-2 py-2">
        <div className="col-4 mb-4">
            <select
                className={"form-control"}
                value={filterValue}
                onChange={(e) => {
                    setFilter(e.target.value);
                }}>
                <option value="">Filtrar tours</option>
                {TOUR_TYPES.map(tt => <option key={tt.id} value={tt.id}>{tt.name}</option>)}
            </select>
        </div>
        {data
            ? <ToursBlock
                data={data}
                guideSlug={guideSlug}
                countrySlug={countrySlug}
            />
            : <CircularProgress/>}
    </div>


};
