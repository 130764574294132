import React, { useState } from 'react';
import {useFormik} from 'formik';
import { Dialog } from '@material-ui/core';

export const NewsletterForm = () => {


    const [submitted, setSubmitted] = useState(0);

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: values => {

            if (values.email !== '') {

                fetch('/api/newsletter_users', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                })
                    .then(res => res.json())

                    .then(res  => {
                        console.info('submitted');
                        setSubmitted(true);
                    });
            }
        },
    });

    return (
        !submitted ? <form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col col-8">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="tu correo electrónico"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                </div>
                <div className="col col-4">
                    <button type="submit" className="btn btn-info">OK</button>
                </div>
            </div>
        </form> : <p className="alert alert-info alert-transparent">Te has suscrito a nuestro boletín de noticias.</p>
    );
};


